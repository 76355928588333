import React from "react";
import HeaderMainNav from "../components/header-main-nav";

const Blog = () => {
  return (
    <>
      <HeaderMainNav scrollToPortfolio={() => {}} scrollToPodcasts={() => {}} />
    </>
  );
};

export default Blog;
